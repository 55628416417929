"use strict";
import 'nodelist-foreach-polyfill';


import slider from './modules/slider';
import hamburger from './modules/hamburger';
import scrolling from './modules/scrolling';



window.addEventListener('DOMContentLoaded', () => {
    slider({
        container: '.offer__slider',
        nextArrow: '.offer__slider-next',
        slide: '.offer__slide',
        prevArrow: '.offer__slider-prev',
        wrapper: '.offer__slider-wrapper',
        field: '.offer__slider-inner'
    });
    scrolling('.pageup');
    hamburger();



})