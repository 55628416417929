const hamburger = () => {
    const hamburger = document.querySelector('.hamburger'),
        overlay = document.querySelector('.menu__overlay'),
        menu = document.querySelector('.menu'),
        closeElem = document.querySelector('.menu__close'),
    close = document.querySelectorAll('.menu__link');


    hamburger.addEventListener('click', () => {
        menu.classList.add('active');
    });

    closeElem.addEventListener('click', () => {
        menu.classList.remove('active');
    });

    overlay.addEventListener('click', () => {
        menu.classList.remove('active');
    });

    window.addEventListener("keydown", (e) => {
        if (e.key == 'Escape') {
            menu.classList.remove('active');
        }
    });

    function closeLinks ()  {
    close.forEach(item => {
        item.addEventListener('click', () => {
            menu.classList.remove('active');
        });
            
    }) ;
    
}
closeLinks(close);
    
}

export default hamburger;